<template>
  <ApoCriaClasse />
</template>

<script>
import ApoCriaClasse from "@/components/documentacaoApoio/ApoioCriaClasse.vue";

export default {
  components: {
    ApoCriaClasse
  }
};
</script>
